export default {
  chartAxisColors: {
    blue: '#256FC2',
    primary: '#1D8DF5',
    secondary_1:'#FFAC4B',
    secondary_3: '#54CC8B',
    red: '#FF5050',
    darkyellow: '#eba80b',
    lightGrey:'lightgrey',
    cyan:'cyan',
    yellow:'yellow',
    white: '#fff',
    black: '#000',
  },
  gridLinesColors: {
    grey: 'rgba(171, 171, 171, 0.15)',
  },
}
